const API_URL = process.env.REACT_APP_API_URL;

/**
 * Uploads a file to the server.
 *
 * @param {File} file - The file object to be uploaded.
 * @returns {Promise<Object>} - A promise that resolves to the response data from the server.
 * @throws {Error} - Throws an error if the upload fails.
 */
async function uploadFile(file) {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(`${API_URL}/upload-file`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
  
export { uploadFile };
