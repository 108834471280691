const API_URL = process.env.REACT_APP_API_URL;

/**
 * Retrieves users from the server.
 *
 * @returns {Promise<Object>} A promise that resolves to the response data from the server.
 * The response object includes success status and the activity logs.
 * @throws {Error} Throws an error if the request to the server fails.
 */
async function getUsers() {
    try {
      const response = await fetch(`${API_URL}/users`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch activity logs");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

export { getUsers };
