import React, { useState, useEffect, useRef } from "react";
import { Table, Button, notification } from "antd";
import { ReloadOutlined, CopyOutlined, LinkOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { useFetchLogs } from "../../hooks/useFetchLogs";
import * as logUtils from "../../utils/logUtils";
import { handleCopy } from "../../utils/fileUtils";

function Archives() {
  const { loading, logs, fetchData } = useFetchLogs();
  const [activityLogs, setActivityLogs] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    logUtils.handleSearch(
      selectedKeys,
      confirm,
      dataIndex,
      originalData,
      setActivityLogs,
      setSearchText,
      setSearchedColumn
    );
  };

  const handleReset = (clearFilters) => {
    logUtils.handleReset(
      clearFilters,
      setActivityLogs,
      originalData,
      setSearchText,
      setSearchedColumn,
      setFilteredInfo,
      setSortedInfo
    );
  };

  const handleChange = (pagination, filters, sorter) => {
    logUtils.handleChange(
      pagination,
      filters,
      sorter,
      setFilteredInfo,
      setSortedInfo
    );
  };

  const getColumnSearchProps = (dataIndex) =>
    logUtils.getColumnSearchProps(
      dataIndex,
      searchInput,
      handleSearch,
      handleReset,
      searchedColumn,
      searchText
    );

  const clearFilters = () => {
    logUtils.clearFilters(
      setFilteredInfo,
      setActivityLogs,
      originalData,
      setSearchText,
      setSearchedColumn
    );
  };

  const clearFiltersAndSorters = () => {
    logUtils.clearFiltersAndSorters(
      setFilteredInfo,
      setSortedInfo,
      setActivityLogs,
      originalData,
      setSearchText,
      setSearchedColumn
    );
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setActivityLogs(logs);
    setOriginalData(logs);
  }, [logs]);

  const columns = [
    {
      title: "Original File Name",
      dataIndex: "filename",
      key: "filename",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => a.filename.localeCompare(b.filename),
      sortOrder: sortedInfo.columnKey === "filename" && sortedInfo.order,
      ...getColumnSearchProps("filename"),
      filteredValue: filteredInfo.filename || null,
    },
    {
      title: "File Type",
      dataIndex: "filetype",
      key: "filetype",
      filters: [
        { text: "Image", value: "Image" },
        { text: "Video", value: "Video" },
        { text: "Audio", value: "Audio" },
        { text: "Document", value: "Document" },
      ],
      onFilter: (value, record) => record.filetype.includes(value),
      filteredValue: filteredInfo.filetype || null,
    },
    {
      title: "File Size (MB)",
      dataIndex: "fileSize",
      key: "fileSize",
      sorter: (a, b) => a.fileSize - b.fileSize,
      sortOrder: sortedInfo.columnKey === "fileSize" && sortedInfo.order,
      render: (fileSize) => (fileSize / 1024 / 1024).toFixed(2),
      filteredValue: filteredInfo.fileSize || null,
    },
    {
      title: "Upload Date",
      dataIndex: "uploadTime",
      key: "uploadTime",
      sorter: (a, b) =>
        moment(a.uploadTime).unix() - moment(b.uploadTime).unix(),
      sortOrder: sortedInfo.columnKey === "uploadTime" && sortedInfo.order,
      render: (text) =>
        moment(text).tz("America/Toronto").format("YYYY-MM-DD hh:mm A z"),
      filteredValue: filteredInfo.uploadTime || null,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            size="small"
            icon={<CopyOutlined />}
            onClick={() => handleCopy(record.newUrl, notification)}
            disabled={!record.newUrl}
          />
          <Button
            size="small"
            icon={<LinkOutlined />}
            onClick={() => window.open(record.newUrl, "_blank")}
            disabled={!record.newUrl}
          />
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={activityLogs}
      loading={loading}
      rowKey="id"
      bordered
      footer={() => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Button onClick={clearFilters}>Clear Filters</Button>
            <Button
              onClick={clearFiltersAndSorters}
              style={{ marginLeft: "10px" }}
            >
              Clear Filters and Sorters
            </Button>
            <Button
              icon={<ReloadOutlined />}
              onClick={fetchData}
              style={{ marginLeft: "10px" }}
              loading={loading}
            />
          </div>
        </div>
      )}
      pagination={logUtils.paginationConfig}
      onChange={handleChange}
      filteredInfo={filteredInfo || null}
      sortedInfo={sortedInfo || null}
    />
  );
}

export default Archives;
