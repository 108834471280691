import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout, Menu, Spin, ConfigProvider } from "antd";

import LoginForm from "./features/login/LoginForm";

import HeaderComponent from "./components/shared/Header";
import FooterComponent from "./components/shared/Footer";

import { useInitializeAuth } from "./hooks/useInitializeAuth";
import { useHandleLogout } from "./hooks/useHandleLogout";
import { useInactivityTracker } from "./hooks/useInactivityTracker";

import { menuItems } from "./config/MenuItems";
import { renderView } from "./utils/appUtils";

import "./App.css";

const { Content } = Layout;

function App() {
  return (
    <ConfigProvider>
      <Router>
        <AppContent />
      </Router>
    </ConfigProvider>
  );
}

function AppContent() {
  const [user, setUser] = useState(null);
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("currentView") || "login"
  );

  const loading = useInitializeAuth({ setUser, setCurrentView });
  const handleAppLogout = useHandleLogout(setUser, setCurrentView);

  const handleViewChange = (newView) => {
    setCurrentView(newView);
    localStorage.setItem("currentView", newView);
  };
  
  useInactivityTracker(user, handleAppLogout);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!user && currentView !== "login") {
    handleViewChange("login");
    return null;
  }

  if (!user) {
    return (
      <LoginForm
        onAuthenticated={(userData) => {
          setUser(userData);
          handleViewChange("upload");
        }}
      />
    );
  }

  return (
    <Layout>
      <HeaderComponent
        user={user}
        onLogout={handleAppLogout}
        onAdminPageClick={() => handleViewChange("adminUsers")}
        onExitAdmin={() => handleViewChange("upload")}
        isAdminPage={currentView.startsWith("admin")}
      />
      <Menu
        onClick={({ key }) => handleViewChange(key)}
        selectedKeys={[currentView]}
        mode="horizontal"
        items={menuItems
          .filter((item) =>
            currentView.startsWith("admin") ? item.isAdmin : !item.isAdmin
          )
          .map(({ isAdmin, ...item }) => item)}
      />
      <Content>
        {renderView(currentView, user, handleAppLogout, handleViewChange)}
      </Content>
      <FooterComponent />
    </Layout>
  );
}

export default App;
