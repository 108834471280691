import { useEffect, useRef } from 'react';
import { notification } from 'antd';

const MAX_INACTIVITY_DURATION = 10 * 60 * 1000;
const WARNING_DURATION = 5 * 60 * 1000;

export function useInactivityTracker(user, handleAppLogout) {
  const notificationKey = 'inactivityWarning';

  const lastActivityRef = useRef(new Date());
  const inactivityWarningSentRef = useRef(false);

  useEffect(() => {
    function checkInactivity() {
      const currentTime = new Date();
      const timeSinceLastActivity = currentTime - lastActivityRef.current;

      if (timeSinceLastActivity >= MAX_INACTIVITY_DURATION) {
        notification.destroy(notificationKey);
        handleAppLogout(true);
      } else if (
        timeSinceLastActivity >= MAX_INACTIVITY_DURATION - WARNING_DURATION &&
        !inactivityWarningSentRef.current
      ) {
        notification.warning({
          key: notificationKey,
          message: 'Session Expiration Warning',
          description: 'You will be logged out due to inactivity in 5 minutes.',
          placement: 'bottomRight',
          duration: 0,
        });
        inactivityWarningSentRef.current = true;
      }
    }

    let inactivityTimer;
    if (user) {
      inactivityTimer = setInterval(checkInactivity, WARNING_DURATION);
    }

    const handleUserActivity = () => {
      lastActivityRef.current = new Date();
      if (inactivityWarningSentRef.current) {
        notification.destroy(notificationKey);
        inactivityWarningSentRef.current = false;
      }
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      clearInterval(inactivityTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [user, handleAppLogout]);
}
