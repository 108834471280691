import { useState, useEffect } from "react";
import { rehydrate } from "../services/AuthService";

export function useInitializeAuth({ setUser, setCurrentView }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      const currentView = localStorage.getItem("currentView") || "login";
      if (currentView !== "login") {
        const currentUser = await rehydrate();
        if (currentUser) {
          setUser(currentUser);
        } else {
          setCurrentView("login");
        }
      }
      setLoading(false);
    };

    initialize();
  }, [setUser, setCurrentView]);

  return loading;
}
