import React from "react";
import { Layout } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import DropdownMenu from "./DropdownMenu";

const { Header } = Layout;

const HeaderComponent = ({
  user,
  onLogout,
  onAdminPageClick,
  onExitAdmin,
  isAdminPage,
}) => {
  const menuItems = isAdminPage
    ? [
        ...(user.isAdmin
          ? [{ key: "1", label: "Exit Admin", icon: <UserOutlined /> }]
          : []),
      ]
    : [
        ...(user.isAdmin
          ? [{ key: "1", label: "Admin Page", icon: <UserOutlined /> }]
          : []),
        { key: "2", label: "Logout", icon: <LogoutOutlined /> },
      ];

  return (
    <Header>
      <img
        src="https://khfilestorage.blob.core.windows.net/public/KHLogo-2.png"
        alt="KH Logo Small Dark"
        className="logo"
      />
      <DropdownMenu
        user={user}
        menuItems={menuItems}
        onMenuClick={(key) => {
          if (key === "1" && isAdminPage) {
            onExitAdmin();
          } else if (key === "1") {
            onAdminPageClick("adminUsers");
          } else if (key === "2") {
            onLogout();
          }
        }}
      />
    </Header>
  );
};

export default HeaderComponent;
