import React from "react";
import { Input, Button, Space, Tooltip, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getFileType } from "./fileUtils";

export const mapData = (logs) => {
  return logs.map((log) => ({
    ...log,
    filetype: getFileType(log.filetype),
  }));
};

export const handleSearch = (
  selectedKeys,
  confirm,
  dataIndex,
  originalData,
  setActivityLogs,
  setSearchText,
  setSearchedColumn
) => {
  confirm();
  setSearchText(selectedKeys[0] || "");
  setSearchedColumn(dataIndex);

  if (selectedKeys.length === 0 || !selectedKeys[0]) {
    return;
  }

  const filteredData = originalData.filter((item) => {
    const itemValue = item[dataIndex];
    if (itemValue === null || itemValue === undefined) {
      return false;
    }
    return itemValue
      .toString()
      .toLowerCase()
      .includes(selectedKeys[0].toLowerCase());
  });

  setActivityLogs(filteredData);
};

export const filterOptions = (logs, key) =>
  Array.from(new Set(logs.map((log) => log[key])))
    .filter((value) => value !== undefined && value !== null)
    .map((value) => ({
      text: value.toString(),
      value: value.toString(),
    }));

export const handleReset = (
  clearFilters,
  setActivityLogs,
  originalData,
  setSearchText,
  setSearchedColumn,
  setFilteredInfo,
  setSortedInfo,
  dataIndex
) => {
  clearFilters();
  setActivityLogs(originalData);
  setSearchText("");
  setSearchedColumn("");
  setFilteredInfo((prevFilters) => {
    const newFilters = { ...prevFilters };
    delete newFilters[dataIndex];
    return newFilters;
  });
  setSortedInfo({});
};

export const getColumnSearchProps = (
  dataIndex,
  searchInput,
  handleSearch,
  handleReset,
  searchedColumn,
  searchText
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) setTimeout(() => searchInput.current?.select(), 100);
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      <Tooltip placement="topLeft" title={text}>
        {text}
      </Tooltip>
    ),
});

export const handleCancel = () => {
  return notification.warning({
    message: "Verification cancelled",
    description: "Purge operation cancelled",
    placement: "bottomRight",
    duration: 0,
  });
};

export const handleChange = (
  pagination,
  filters,
  sorter,
  setFilteredInfo,
  setSortedInfo
) => {
  setFilteredInfo(filters);
  setSortedInfo(sorter);
};

export const clearFilters = (
  setFilteredInfo,
  setActivityLogs,
  originalData,
  setSearchText,
  setSearchedColumn
) => {
  setFilteredInfo({});
  setActivityLogs(originalData);
  setSearchText("");
  setSearchedColumn("");
};

export const clearFiltersAndSorters = (
  setFilteredInfo,
  setSortedInfo,
  setActivityLogs,
  originalData,
  setSearchText,
  setSearchedColumn
) => {
  setFilteredInfo({});
  setSortedInfo({});
  setActivityLogs(originalData);
  setSearchText("");
  setSearchedColumn("");
};

export const paginationConfig = {
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "50"],
  defaultPageSize: 10,
};

export const expandedRowRender = (record) => (
  <p style={{ margin: 0 }}>{record.errorMessage}</p>
);

export const rowExpandable = (record) =>
  record.status === "Error" && record.errorMessage;
