const API_URL = process.env.REACT_APP_API_URL;

/**
 * Retrieves activity logs from the server.
 *
 * @returns {Promise<Object>} A promise that resolves to the response data from the server.
 * The response object includes success status and the activity logs.
 * @throws {Error} Throws an error if the request to the server fails.
 */
async function getLogs() {
    try {
      const response = await fetch(`${API_URL}/activity-logs`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch activity logs");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  /**
 * Purges logs from the server where corresponding media doesn't exist.
 *
 * @returns {Promise<Object>} A promise that resolves to the response data from the server.
 * The response object includes success status and the activity logs.
 * @throws {Error} Throws an error if the request to the server fails.
 */
async function verifyLogs() {
  try {
    const response = await fetch(`${API_URL}/verify-logs`, {
      method: 'POST',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error("Failed to purge logs");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export { getLogs, verifyLogs };
