export const allowedFileTypes = [
  // Images
  "image/jpeg",
  "image/png",
  "image/gif",

  // Documents
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/x-notebook",

  // Audio
  "audio/mpeg",
  "audio/mp4",
  "audio/wav",

  // Video
  "video/mp4",
  "video/x-m4v",
  "video/mpeg",
  "video/x-ms-wmv",
  "video/quicktime",
  "video/x-msvideo",
  "application/x-shockwave-flash",

  // Other
  ".ins",
  ".isf",
  ".te",
  ".xbk",
  ".ist",
  ".kmz",
  ".kes",
  ".flp",
  ".wxr",
  ".xml",
  ".fjsw",
  "application/zip",
  "application/epub+zip",
];

export const beforeUpload = (file, api) => {
  const isFileTypeValid = allowedFileTypes.includes(file.type);
  const isSizeValid = file.size <= 50 * 1024 * 1024;

  if (!isFileTypeValid) {
    api.error({
      message: "Invalid File Type",
      description: `Please ensure you're uploading an allowed file format.`,
      duration: 0,
    });
    return false;
  }

  if (!isSizeValid) {
    api.error({
      message: "File Too Large",
      description: `The file size exceeds the maximum limit of 50MB.`,
      duration: 0,
    });
    return false;
  }

  return true;
};

export const getFileType = (mimetype) => {
  if (mimetype.startsWith("image")) return "Image";
  if (mimetype.startsWith("video")) return "Video";
  if (mimetype.startsWith("audio")) return "Audio";
  return "Document";
};

export const handleCopy = (url, notification) => {
  navigator.clipboard.writeText(url);
  notification.success({
    message: "URL Copied",
    description: "URL copied to clipboard",
    placement: "bottomRight",
    duration: 3,
  });
};