import React, { useRef } from "react";
import { Card, Form, Input, Button, notification } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { handleLogin } from "../../services/AuthService";

import "./LoginForm.css";

const LoginForm = ({ onAuthenticated }) => {
  const formRef = useRef();
  let isNotificationVisible = false;

  const handleLoginForm = async (values) => {
    let errorDetails = { usernameError: null, passwordError: null };

    try {
      const response = await handleLogin(values.username, values.password);

      if (response.success) {
        onAuthenticated(response.user);
      } else {
        const errorType = response.errorType;
        if (errorType === 0) {
          errorDetails.usernameError = "Username does not exist";
        } else if (errorType === 2) {
          errorDetails.passwordError = "Too many login attempts";
          if (!isNotificationVisible) {
            isNotificationVisible = true;
            notification.error({
              message: "Too many login attempts",
              description: (
                <span>
                  Please contact <a href="mailto:charles@knowhistory.ca">charles@knowhistory.ca</a> to have your account unlocked.
                </span>
              ),
              placement: "bottomRight",
              duration: 0,
              onClose: () => { isNotificationVisible = false; }
            });
          }
        } else if (errorType === 1) {
          errorDetails.passwordError = "Incorrect password";
        }
      }
    } catch (error) {
      console.error("Login error:", error);
    }

    if (errorDetails.usernameError) {
      formRef.current.setFields([
        { name: "username", errors: [errorDetails.usernameError] },
      ]);
    }

    if (errorDetails.passwordError) {
      formRef.current.setFields([
        { name: "password", errors: [errorDetails.passwordError] },
      ]);
    }
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <div className="login-logo">
          <img
            src="https://khfilestorage.blob.core.windows.net/public/KHLogo-3.png"
            alt="KH Logo Dark"
          />
        </div>
        <Form
          ref={formRef}
          name="login_form"
          onFinish={handleLoginForm}
          layout="vertical"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              type="email"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              danger
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
