import { useState, useCallback } from "react";
import { notification } from "antd";
import { verifyLogs } from "../services/LogService";

export const useVerifyLogs = () => {
  const [loading, setLoading] = useState(false);

  const verifyData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await verifyLogs();
      if (response.success) {
        notification.success({
          message: "Non-existent logs have been purged.",
          description: response.message,
          placement: "bottomRight",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Cannot verify logs",
          description: "An error occurred while verifying logs",
          placement: "bottomRight",
          duration: 0,
        });
      }
    } catch (error) {
      notification.error({
        message: "Cannot verify logs",
        description: "An error occurred while verifying logs",
        placement: "bottomRight",
        duration: 0,
      });
    }
    setLoading(false);
  }, []);

  return { loading, verifyData };
};
