import React from "react";
import FileUpload from "../features/upload/Upload";
import Archives from "../features/archives/Archives";
import Admin from "../features/admin/Admin";


export function renderView(currentView, user) {
  const viewComponents = {
    upload: <FileUpload user={user} />,
    archives: <Archives user={user} />,
    admin: <Admin user={user} currentView={currentView} />,
  };

  if (currentView.startsWith("admin")) {
    return React.cloneElement(viewComponents.admin, { currentView });
  }

  return viewComponents[currentView] || null;
}
