import { UploadOutlined, HistoryOutlined, UserOutlined, FileSearchOutlined } from "@ant-design/icons";

export const menuItems = [
  {
    label: "Upload",
    key: "upload",
    icon: <UploadOutlined />,
    isAdmin: false,
  },
  {
    label: "Archives",
    key: "archives",
    icon: <HistoryOutlined />,
    isAdmin: false,
  },
  {
    label: "User Management",
    key: "adminUsers",
    icon: <UserOutlined />,
    isAdmin: true,
  },
  {
    label: "Activity Logs",
    key: "adminLogs",
    icon: <FileSearchOutlined />,
    isAdmin: true,
  },
];