import React from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const FileDragger = ({ ...props }) => {
  const { Dragger } = Upload;

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag a file to this area to upload
      </p>
      <p className="ant-upload-hint">Support for single file upload only</p>
    </Dragger>
  );
};

export default FileDragger;
