const API_URL = process.env.REACT_APP_API_URL;

/**
 * Handle user login.
 * @param {string} username - The username of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise<object>} The response data.
 * @throws {Error} - Will throw an error if the login is unsuccessful.
 */
async function handleLogin(username, password) {
  try {
    const response = await fetch(`${API_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "An error occurred.");
    }
    
    return data;
  } catch (error) {
    throw error;
  }
}

/**
 * Initiates the user logout process.
 * @param {boolean} dueToInactivity - Optional parameter to indicate
 * whether the logout was due to inactivity. Defaults to false.
 * @throws {Error} Throws an error if the logout request to the server
 * is unsuccessful, with the error message "Failed to log out".
 * @returns {void} This function does not return any value.
 */
async function handleLogout(dueToInactivity = false) {
  try {
    const response = await fetch(`${API_URL}/logout`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ loggedOutDueToInactivity: dueToInactivity })
    });

    if (!response.ok) {
      throw new Error("Failed to log out");
    }
  } catch (error) {
    throw error;
  }
}

/**
 * Attempts to retrieve the current user's information from the server, maintaining the user's state across page reloads.
 * @returns {Object|null} The user object if a session is active and user is authenticated, or null if no active session.
 * @throws {Error} Logs an error to the console if the network request fails, but does not propagate the error.
 */
async function rehydrate() {
  try {
    const response = await fetch(`${API_URL}/rehydrate`, { credentials: 'include' });
    if (response.ok) {
      const data = await response.json();
      return data.user;
    }
  } catch (error) {
    throw(error);
  }
  return null;
}

export { handleLogin, handleLogout, rehydrate };
