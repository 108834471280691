import React from "react";
import { Row, Col } from "antd";
import ActivityLogs from "./ActivityLogs";
import UserManagement from "./Users";

const Admin = ({ user, currentView }) => {

  if (!user || !user.isAdmin) {
    return <div>You do not have permission to access this page.</div>;
  }

  return (
    <>
      <Row gutter={16}>
        {currentView === "adminUsers" && (
          <>
            <Col span={24}>
              <UserManagement currentUserId = { user.id }/>
            </Col>
          </>
        )}
        {currentView === "adminLogs" && (
          <>
            <Col span={24}>
              <ActivityLogs />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default Admin;
