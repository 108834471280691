import React from "react";
import { Card, Button } from "antd";
import { CopyOutlined, CloseCircleOutlined } from "@ant-design/icons";

const UploadedFileCard = ({ file, onCopy, onRemove }) => (
  <Card
    key={file.id}
    title={file.name}
    style={{ marginTop: 16 }}
    extra={
      <>
        <Button
          icon={<CopyOutlined />}
          onClick={() => onCopy(file.url)}
          style={{ marginRight: "10px" }}
        />
        <Button
          icon={<CloseCircleOutlined />}
          onClick={() => onRemove(file.url)}
        />
      </>
    }
  >
    <b>{file.url}</b>
  </Card>
);

export default UploadedFileCard;
