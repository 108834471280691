import React, { useState, useEffect } from "react";
import {
  Table,
  Tooltip,
  Button,
  Popconfirm,
  Space,
  Modal,
  Form,
  Input,
  Select,
  message,
  InputNumber,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  SaveOutlined,
  CloseOutlined,
  KeyOutlined,
  ReloadOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { useFetchUsers } from "../../hooks/useFetchUsers";

const UserManagement = ({ currentUserId }) => {
  const { loading, users, fetchData } = useFetchUsers();
  const [userData, setUserData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [currentEditingUser, setCurrentEditingUser] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const formattedUsers = users.map((user) => ({ ...user, key: user.id }));
    setUserData(formattedUsers);
  }, [users]);

  const isEditing = (record) => record.key === editingKey;
  const isAnyEditing = editingKey !== "";

  const isActionDisabled = (record) => {
    return editingKey !== "" && editingKey !== record.key;
  };

  const handleEdit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const handleCancel = () => {
    setEditingKey("");
  };

  const handleSave = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...userData];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setUserData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setUserData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleLockToggle = async (userId) => {
    // Add lock toggle functionality here
  };

  const handleChangePassword = (userId) => {
    setCurrentEditingUser(userId);
    setChangePasswordVisible(true);
  };

  const handleDelete = async (userId) => {
    if (userId === currentUserId) {
      message.error("You cannot delete yourself!");
      return;
    }
    // Add delete functionality here
  };

  const rolesOptions = [
    { label: "Admin", value: "Admin" },
    { label: "User", value: "User" },
  ];

  const ChangePasswordModal = ({ userId, visible, onOk, onCancel }) => {
    const [passwordForm] = Form.useForm();

    const onModalOk = () => {
      passwordForm
        .validateFields()
        .then((values) => {
          onOk(userId, values.password);
          passwordForm.resetFields();
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    };

    return (
      <Modal
        title="Change Password"
        open={visible}
        onOk={onModalOk}
        onCancel={onCancel}
      >
        <Form form={passwordForm}>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input the new password!" },
            ]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    if (dataIndex === "roles") {
      inputNode = editing ? (
        <Select style={{ width: "100%" }} options={rolesOptions} />
      ) : (
        <Select
          defaultValue={record.roles}
          style={{ width: 120 }}
          disabled
          options={rolesOptions}
        />
      );
    } else {
      inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "currentSession",
      key: "currentSession",
      width: 25,
      render: (text, record) => {
        const { lastActivity } = record.currentSession || {};
        return (
          <div>
            {lastActivity ? (
              <span className="dot dot-green" />
            ) : (
              <span className="dot dot-red" />
            )}
          </div>
        );
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      editable: false,
    },
    {
      title: "First Name",
      dataIndex: ["names", "firstName"],
      key: "firstName",
      editable: true,
      // Added conditional check for editing state
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: ["names", "firstName"],
        title: "First Name",
        editing: isEditing(record),
      }),
    },
    {
      title: "Last Name",
      dataIndex: ["names", "lastName"],
      key: "lastName",
      editable: true,
      // Added conditional check for editing state
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: ["names", "lastName"],
        title: "Last Name",
        editing: isEditing(record),
      }),
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "select",
        dataIndex: "roles",
        title: "Roles",
        editing: isEditing(record),
      }),
    },
    {
      title: "Actions",
      key: "action",
      width: 4 * 40 + 16,
      render: (_, record) => {
        const editable = isEditing(record);
        const isAnyEditing = editingKey !== "";
        return editable ? (
          <Space size="middle">
            <Tooltip title="Save">
              <Button
                icon={<SaveOutlined />}
                onClick={() => handleSave(record.key)}
              />
            </Tooltip>
            <Tooltip title="Cancel">
              <Button icon={<CloseOutlined />} onClick={handleCancel} />
            </Tooltip>
          </Space>
        ) : (
          <Space size="middle">
            <Tooltip title="Edit">
              <Button
                disabled={isAnyEditing}
                icon={<EditOutlined />}
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
            {/* Disable other buttons based on editing state */}
            <Tooltip title="Change Password">
              <Button
                disabled={isActionDisabled(record)}
                icon={<KeyOutlined />}
                onClick={() => handleChangePassword(record.key)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure you want to delete this user?"
                onConfirm={() => handleDelete(record.key)}
                disabled={
                  isActionDisabled(record) || record.key === currentUserId
                }
              >
                <Button
                  disabled={
                    isActionDisabled(record) || record.key === currentUserId
                  }
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip
              title={
                record.accountStatus.isLocked ? "Unlock User" : "Lock User"
              }
            >
              <Popconfirm
                title={`Are you sure you want to ${
                  record.accountStatus.isLocked ? "unlock" : "lock"
                } this user?`}
                onConfirm={() => handleLockToggle(record.key)}
                disabled={isActionDisabled(record) || record.key === currentUserId}
              >
                <Button
                  disabled={isActionDisabled(record) || record.key === currentUserId}
                  icon={
                    record.accountStatus.isLocked ? (
                      <LockOutlined />
                    ) : (
                      <UnlockOutlined />
                    )
                  }
                  onClick={() => handleLockToggle(record.key)}
                  style={record.accountStatus.isLocked ? { color: "red" } : {}}
                ></Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "roles" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={userData}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: handleCancel,
        }}
        onRow={(record, rowIndex) => {
          return {
            style: {
              pointerEvents:
                isAnyEditing && !isEditing(record) ? "none" : "auto",
            },
          };
        }}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => {
                /* handle add user logic */
              }}
              icon={<PlusOutlined />}
              style={{ marginRight: 8 }}
            >
              Add User
            </Button>
            <Button onClick={fetchData} icon={<ReloadOutlined />} />
          </div>
        )}
      />
      <ChangePasswordModal
        userId={currentEditingUser}
        visible={changePasswordVisible}
        onOk={(userId, newPassword) => {
          // Send password change request
          console.log(`Change password for user ${userId}: ${newPassword}`);
          setChangePasswordVisible(false);
        }}
        onCancel={() => {
          setChangePasswordVisible(false);
        }}
      />
    </Form>
  );
};

export default UserManagement;
