import { notification } from "antd";
import { handleLogout } from "../services/AuthService";

export function useHandleLogout(
  setUser,
  setCurrentView
) {
  const handleAppLogout = async (dueToInactivity = false) => {
    await handleLogout(dueToInactivity);
    setUser(null);
    setCurrentView('login');
    localStorage.clear();

    if (dueToInactivity) {
      notification.warning({
        message: "Logged Out",
        description: "You were logged out due to inactivity.",
        placement: "bottomRight",
        duration: 0,
      });
    }
  };

  return handleAppLogout;
}