import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterComponent = () => {
    const currentYear = new Date().getFullYear();

    return (
        <Footer>Copyright ©{currentYear} Know History Inc.</Footer>
    );
}

export default FooterComponent;
