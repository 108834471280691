import React from "react";
import { Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

const DropdownMenu = ({ user, menuItems, onMenuClick }) => {
  const items = menuItems.map((item) => ({
    key: item.key,
    label: (
      <span>
        {item.icon} {item.label}
      </span>
    ),
  }));

  const handleMenuClick = ({ key }) => {
    onMenuClick(key);
  };

  return (
    <Dropdown
      menu={{ items, onClick: handleMenuClick }}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <Button>
        {user.username} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default DropdownMenu;
