import React, { useState, useEffect } from "react";
import { Spin, notification } from "antd";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import FileDragger from "../../components/upload/FileDragger";
import UploadedFileCard from "../../components/upload/UploadedFileCard";
import { uploadFile } from "../../services/FileService";
import { beforeUpload, handleCopy } from "../../utils/fileUtils";

function Upload() {
  const [fileList, setFileList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [api, contextHolder] = notification.useNotification({
    placement: "bottomRight",
  });

  const hideOverlay = () => {
    const overlay = document.querySelector(".upload-overlay");
    if (overlay) overlay.classList.add("fade-out");

    const delay = uploadStatus === "exception" ? 5000 : 3000;

    setTimeout(() => {
      setUploadStatus("idle");
      setUploading(false);
      if (overlay) overlay.classList.remove("fade-out");
    }, delay);
  };

  const removeCard = (url) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.url !== url)
    );
  };

  const request = async ({ file, onSuccess, onError }) => {
    setUploading(true);
    setUploadStatus("active");

    try {
      const response = await uploadFile(file);
      setUploadStatus("success");
      hideOverlay();
      onSuccess(null, file);
      setUploadedFiles((prev) => [...prev, response.data]);
      api.success({
        message: "Upload Successful",
        description: `${file.name} file uploaded successfully.`,
      });
    } catch (error) {
      setUploadStatus("exception");
      hideOverlay();
      onError(error);
      api.error({
        message: "Upload Error",
        description: `An error occurred while uploading ${file.name}: ${error.message}`,
      });
    }
  };

  const UploadingText = () => {
    const [dots, setDots] = useState("");

    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + "." : ""));
      }, 500);

      return () => clearInterval(interval);
    }, []);

    return <div className="uploading-text">Uploading{dots}</div>;
  };

  const adjustedBeforeUpload = (file) => beforeUpload(file, api);

  return (
    <div>
      {contextHolder}

      {uploading && (
        <div className="upload-overlay">
          {uploadStatus === "success" ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: 48 }} />
          ) : uploadStatus === "exception" ? (
            <CloseCircleOutlined style={{ color: "red", fontSize: 48 }} />
          ) : (
            <div className="uploading-container">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
              <UploadingText />
            </div>
          )}
        </div>
      )}

      <FileDragger
        customRequest={request}
        fileList={fileList}
        showUploadList={false}
        beforeUpload={adjustedBeforeUpload}
        onChange={({ fileList: newFileList }) => setFileList(newFileList)}
      />

      {uploadedFiles.map((file) => (
        <UploadedFileCard
          file={file}
          key={file.url}
          onCopy={() => handleCopy(file.url, api)}
          onRemove={() => removeCard(file.url)}
        />
      ))}
    </div>
  );
}

export default Upload;
