import { useState, useCallback } from "react";
import { notification } from "antd";
import { getLogs } from "../services/LogService";
import * as logUtils from "../utils/logUtils";

export const useFetchLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getLogs();
      if (response.success && Array.isArray(response.logs)) {
        const data = logUtils.mapData(response.logs);
        setLogs(data);
      } else {
        notification.warning({
          message: "Cannot retrieve data",
          description: "An error occurred while retrieving data",
          placement: "bottomRight",
          duration: 0,
        });
      }
    } catch (error) {
      notification.error({
        message: "Cannot retrieve data",
        description: `An error occurred while retrieving data: ${error.message}`,
        placement: "bottomRight",
        duration: 0,
      });
    }
    setLoading(false);
  }, []);

  return { logs, loading, fetchData };
};
